import { queryOptions } from '@tanstack/vue-query'
import { useApi } from '~/domains/global/composables/useApi'

export const getCheckoutConfigsQuery = () => {
  return queryOptions({
    queryKey: ['checkoutConfig'],
    queryFn: async () => {
      const api = useApi()
      const data = await api<Config>('/', {
        method: 'GET',
      })

      return data
    },
    staleTime: 1000 * 60 * 60,
  })
}

export type Plan = {
  id: string
  months: number
  price: number
  planName: string
  description: string
  maxInstallments: number
}

export type Limits = {
  basic: number
  premium: number
  premiumPerDay: number
}

export type Config = {
  plans: Plan[]
  limits: Limits
}
