import { queryOptions } from '@tanstack/vue-query'
import type { AuthUser } from '../types'
import { useApi } from '~/domains/global/composables/useApi'

export const reAuthQuery = (token: string) => {
  return queryOptions({
    queryKey: ['reauth'],
    queryFn: async () => {
      const api = useApi()
      const data = await api<AuthUser>('/users/reauth', {
        method: 'POST',
        body: { token },
      })

      return data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })
}
